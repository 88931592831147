import React from 'react';

import styled, { scale, baseline, BREAKPOINTS, zoneColors } from '../styled';

const Layout = styled.div`
  margin-bottom: ${scale(4)};
`;

const AdvantageList = styled.div`
  margin-top: ${scale(1)};
  margin-bottom: ${scale(1)};

  @media (min-width: ${BREAKPOINTS.m}) {
    margin-bottom: ${scale(2)};
  }
`;

const Advantage = styled.p`
  text-align: center;

  @media (min-width: ${BREAKPOINTS.m}) {
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: ${scale(0.5)};
    grid-template-areas:
      'title stripes'
      'description description';

    text-align: inherit;
  }
`;

const Title = styled.span`
  display: block;

  font-weight: bold;
  ${baseline(22, 2, 'm')};
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.m}) {
    grid-area: title;

    ${baseline(28, 2, 'l')};
  }
`;

const Description = styled.span`
  display: block;

  color: #000;

  @media (min-width: ${BREAKPOINTS.m}) {
    grid-area: description;
  }
`;

const Stripes = styled.span`
  display: block;
  position: relative;
  height: ${scale(1)};

  @media (min-width: ${BREAKPOINTS.m}) {
    grid-area: stripes;

    height: ${scale(2)};
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;

    height: 1px;
    background-color: #000;
  }

  &:before {
    top: ${scale(0.375)};

    @media (min-width: ${BREAKPOINTS.m}) {
      top: ${scale(1.25)};
    }
  }

  &:after {
    bottom: ${scale(0.375)};

    @media (min-width: ${BREAKPOINTS.m}) {
      bottom: ${scale(0.5)};
    }
  }
`;

const AdvertisingAdvantageList: React.FC = () => {
  return (
    <Layout>
      <h2>Communiquer et promouvoir votre entreprise</h2>
      <p>Les avantages d&apos;une publicité par Green Course :</p>
      <AdvantageList>
        <Advantage style={{ color: zoneColors[0] }}>
          <Title>Visibilité accrue</Title>
          <Description>
            Les coursiers parcourent près de 80 kilomètres par jour dans la région Nantaise, dans un
            rayon de 12 kilomètres autour du centre ville. Nous avons accès à des places où seul le
            vélo peut circuler. Profitez de cette large zone de couverture !
          </Description>
          <Stripes />
        </Advantage>
        <Advantage style={{ color: zoneColors[1] }}>
          <Title>Image de marque</Title>
          <Description>
            Dynamisme, dépassement de soi et prouesses collectives sont l&apos;essence même du
            cyclisme. Bénéficiez de ses valeurs pour communiquer avec votre audience.
          </Description>
          <Stripes />
        </Advantage>
        <Advantage style={{ color: zoneColors[2] }}>
          <Title>Originalité</Title>
          <Description>
            Un support publicitaire mobile augmente la visibilité de votre campagne. La singularité
            d&apos;une communication à vélo permet de vous démarquer. Cultivez votre différence
            grâce à un canal atypique. Optez pour la publicité à vélo !
          </Description>
          <Stripes />
        </Advantage>
        <Advantage style={{ color: zoneColors[3] }}>
          <Title>Écologique</Title>
          <Description>
            Nous portons un grand intérêt pour le développement durable. Réutilisez votre support
            pour vos prochains évènements ! Pas de Gaspillage chez Green Course !
          </Description>
          <Stripes />
        </Advantage>
      </AdvantageList>
    </Layout>
  );
};

export default AdvertisingAdvantageList;
