import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import * as GatsbyTypes from '../graphqlTypes';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PageTitle from '../components/PageTitle';
import Banner from '../components/Banner';
import CenteredBox from '../components/CenteredBox';
import AdvertisingAdvantageList from '../components/AdvertisingAdvantageList';
import AdvertisingForm from '../components/AdvertisingForm';

const PimpMyTrailerPage: React.FC = () => {
  const { file, contactFile } = useStaticQuery<GatsbyTypes.GetPimpMyTrailerPageQuery>(
    graphql`
      query GetPimpMyTrailerPage {
        file(name: { eq: "pimp-my-trailer" }) {
          id
          childMarkdownRemark {
            id
            html
            frontmatter {
              title
              banner {
                childImageSharp {
                  gatsbyImageData(height: 440, layout: FULL_WIDTH)
                }
              }
              seo {
                title
                description
                image {
                  publicURL
                }
              }
            }
          }
        }
        contactFile: file(name: { eq: "contact" }) {
          id
          childMarkdownRemark {
            id
            frontmatter {
              phoneNumber
            }
          }
        }
      }
    `
  );

  const seoData = file?.childMarkdownRemark?.frontmatter?.seo;
  /* istanbul ignore next */
  if (!seoData?.title || !seoData.description) {
    throw new Error('[Missing data] Page SEO meta');
  }

  const pageData = file?.childMarkdownRemark?.frontmatter;
  /* istanbul ignore next */
  if (!pageData?.title) {
    throw new Error('[Missing data] Page content');
  }

  const contactPageData = contactFile?.childMarkdownRemark?.frontmatter;
  /* istanbul ignore next */
  if (!contactPageData?.phoneNumber) {
    throw new Error('[Missing data] Page content');
  }

  const bannerImg = pageData.banner?.childImageSharp?.gatsbyImageData;

  return (
    <Layout>
      <SEO
        title={seoData.title}
        description={seoData.description}
        image={seoData.image?.publicURL}
      />
      <PageTitle>{pageData.title}</PageTitle>
      {bannerImg && <Banner img={bannerImg} />}
      <CenteredBox>
        <AdvertisingAdvantageList />
        <AdvertisingForm phoneNumber={contactPageData?.phoneNumber} />
      </CenteredBox>
    </Layout>
  );
};

export default PimpMyTrailerPage;
