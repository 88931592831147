import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import styled, { scale, BREAKPOINTS } from '../styled';

const Layout = styled.section`
  margin-bottom: ${scale(1)};

  @media (min-width: ${BREAKPOINTS.s}) {
    margin-bottom: ${scale(2)};
  }
`;

function getBannerHeight(): string {
  const defaultHeight = scale(16);

  /* istanbul ignore next */
  if (typeof window === 'undefined') return defaultHeight;

  /* istanbul ignore next */
  return window.matchMedia(`(min-width: ${BREAKPOINTS.s})`).matches ? defaultHeight : scale(10);
}

const Banner: React.FC<{
  img: IGatsbyImageData;
}> = ({ img, ...props }) => {
  return (
    <Layout {...props}>
      <GatsbyImage image={img} alt="" style={{ height: getBannerHeight() }} />
    </Layout>
  );
};

export default Banner;
